.bodyContainer {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.commonFooter {
    font-family: 'IBM Plex sans', sans-serif;
    z-index: 10;
    background: transparent;
    position: relative;
    width: 100%;
    font-size: 14px;
    transition: all 0.5s;
    min-height: 128px;
    display: flex;
    align-items: center;
    color: #909ebb;
}

#footer[data-theme="dark"] .commonFooter {
    color: #909ebb;
}

#footer[data-theme="light"] .commonFooter {
    color: #ffffff;
}

#footer[data-theme="light"] .commonFooter svg path {
    fill: #ffffff;
}

.commonFooter a {
    border-bottom: none;
    text-decoration: none;
}

.commonFooterContent {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 940px;
    margin: 0 auto;
}

.footerContentItem {
    display: flex;
    padding-right: 6px;
    padding-left: 6px;
}

.footerContentIcon {
    display: flex;
    align-items: center;
    padding-right: 18px;
}

.footerContentText {
    color: inherit;
    display: flex;
    align-items: center;
}

.footerContentText a {
    color: inherit;
}

#usedesk-messenger {
    font-size: 14px;
}

@media screen and (max-width: 800px) {
    .commonFooterContent {
        flex-direction: column;
        justify-content: flex-start;
    }

    .footerContentItem {
        padding-bottom: 10px;
    }
}

@media screen and (max-width: 1400px) {
    .footerContentIcon {
        padding-right: 6px;
    }

    .commonFooter {
        font-size: 12px;
    }
}
