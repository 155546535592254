.commonsHeader {
    font-family: 'IBM Plex sans', sans-serif;
    z-index: 11;
    height: 64px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    font-size: 16px;
    transition: all 0.5s;
    border-bottom: 1px solid #E4E7F2;
}

.commonsHeaderHeader {
    background: black;
}

.commonsHeader a {
    border-bottom: none;
    text-decoration: none;
}

.logo {
    margin-left: 28px;
    max-width: 265px;
    display: flex;
    align-items: center;
}

.logoDark svg {
    filter: hue-rotate(353deg) brightness(100);
}

.mobileBtn {
    padding-right: 10px;
    padding-left: 10px;
    background: none;
    border: none;
    cursor: pointer;
    height: 100%;
}

.mobileBtn svg path {
    fill: #3B4168;
}

.commons-header__profile-item {
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 28px;
    padding-right: 28px;
}

.commons-header__profile-item--active, .commons-header__profile-item:hover {
    background: #F5FBFF;
}

.commons-header__profile-image {
    padding-right: 16px;
    display: flex;
    align-items: center;
}

.commons-header__profile-name {
    color: #6F7C98;
}

@media screen and (min-width: 1920px) {
    .logo {
        height: 36px;
    }
}

@media screen and (max-width: 1600px) {
    .commonsHeader {
        font-size: 14px;
    }
}

@media screen and (max-width: 756px) {
    .commonsHeader {
        padding: 16px 20px 16px 0;
    }

    .logo {
        height: 24px;
    }
}
