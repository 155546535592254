.commonsLoginBtn.ant-btn {
  height: 44px;
  border-radius: 4px;
  font-family: 'IBM Plex sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.commonsLoginBtn.ant-btn:not(.ant-btn-block) {
  max-width: 215px;
}
.commonsLoginBtn.ant-btn .anticon {
  line-height: 0;
  font-size: 20px;
}
.commonsLoginBtn.ant-btn-primary {
  background: linear-gradient(94.25deg,#007BE0 -0.43%,#0058A0 54.69%);
  border-color: #0071CE;
  color: white;
}
.commonsLoginBtn.ant-btn-primary:hover {
  background: #0071CE;
  transition: none;
}
.commonsLoginBtn.ant-btn-ghost {
  border-color: #0071CE;
  color: #0071CE;
}
.commonsLoginBtn.ant-btn-link {
  display: inline-block;
  height: fit-content;
  padding: 0;
  color: #0071CE;
}
.commonsLoginBtn.ant-btn__download {
  height: initial;
  width: initial;
  padding: initial;
  border: initial;
  background: initial;
  font-size: 18px;
}
.commonsLoginBtn.ant-btn__download:focus {
  color: #333;
  background: #F5F6F8;
  border-color: #F5F6F8;
}
.commonsLoginBtn.ant-btn__download:hover {
  color: #333;
  background: #F5F6F8;
  border-color: #F5F6F8;
}

.enterBtn {
  width: 136px;
  height: 44px;
  border: 1px solid #0071CE;
  color: #0071CE;
  border-radius: 4px;
  background: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin: 8px 0 0 28px;
}

.enterBtn:disabled, .enterBtn[disabled] {
  border: 1px solid #6F7C98;
  color: #6F7C98;
  cursor: default;
}

@media screen and (max-width: 650px) {
  .enterBtn {
    width: 90%;
    margin: 0 auto;
  }
}
