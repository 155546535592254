.commonsNavMenu {
    user-select: none;
    display: flex;
    align-items: center;
    height: 100%;
}

.commonsNavMobileMenu {
    z-index: 20;
    background: #FEFEFE;
    right: 0;
    top: 0;
    box-shadow: -18px 0 30px 2px #BCBCBC;
    display: flex;
    justify-content: space-between;
    min-height: 140px;
    padding: 40px 15px
}

.mobileMenuLabel:hover {
    background: #F5FBFF;
}

.mobileMenuLabelDark {
    color: #fff;
}

.mobileMenuLabelDark:hover {
    color: #0071CE;
}

.commonsNavMobileMenuHeader {
    background: black;
}

.commonsNavMobileMenu a {
    border-bottom: none;
    text-decoration: none;
}

.closeBtnDark svg {
    filter: hue-rotate(353deg) brightness(100);
}

.closeBtn {
    border: none;
    background: none;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px;
    padding: 20px 10px;
    cursor: pointer;
    z-index: 1;
}

.commonsNavMobileMenu ul {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    gap: 20px 25px;
    list-style: none;
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    font-size: 18px;
    color: #6F7C98;
    width: 70%;
}

.commonsNavMobileMenu ul li {
    padding: 0;
    margin: 0;
    max-width: 170px;
    height: 20px;
}

.commonsNavMobileMenu ul li span {
    padding-left: 12px;
    color: #3B4168;
}

.commonsNavMobileMenu ul li a {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    color: inherit;
    padding: 8px 12px 8px 24px;
}

.commonsNavMobileMenu ul li .isActive, .commons-nav-mobile-menu ul li a:hover {
    background: #F5FBFF;
}

.enterBtnDark{
    background: black;
    border: 1px solid #fff;
    color: #fff;
}

.commonsNavMenuItem {
    height: 100%;
    display: flex;
    align-items: center;
    color: #3B4168;
    padding-left: 28px;
    padding-right: 28px;
    justify-content: flex-end;
}


.commonsNavMenuItemNotActive {
    color: #3B4168;
}

.commonsNavMenuItemActive {
    color: #0071CE;
}

.commonsNavMenuItemActive, .commonsNavMenuItem:hover {
    background: #F5FBFF;
    color: #0071CE;
}

.itemIcon {
    display: flex;
    align-items: center;
    padding-right: 14px;
}

.itemLabel:hover {
    background: #F5FBFF;
}

.itemLabelDark {
    color: #fff;
}

.itemLabelDark:hover {
    color: #0071CE;
}

.user {
    display: flex;
    column-gap: 10px;
    align-items: center;
    position: relative;
    cursor: pointer;
    padding: 8px 24px 45px;
    border: 1px solid transparent;
}

.userInf {
    display: flex;
    align-items: center;
    padding: 6px 8px;
    box-shadow: 0 4px 15px rgba(33, 36, 55, 0.12);
    border-radius: 12px;
    height: 44px;
}


.exit {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 8px 10px;
    width: 40px;
    height: 44px;
}
.user svg{
    height: 20px;
    width: 21px;
}

.user svg path {
    fill: #F39A9A;
}

.userGazprom {
    padding-top: 40px;
}

.userAvatar {
    position: relative;
    width: 32px;
    height: 32px;
    font-size: 0;
}

.userAvatar img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 100%;
}

.userName {
    padding-left: 12px;
    white-space: nowrap;
    font-family: 'IBM Plex sans', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #3B4168;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 380px;
}

.user:hover {
    background: #F5FBFF;
}

.ant-dropdown {
    max-width: 170.969px !important;
}

@media screen and (max-width: 1600px) {
    .commonsNavMenuItem {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media  screen and (max-width: 780px) {
    .commonsNavMobileMenu ul{
        gap: 33px;
    }
}

@media  screen and (max-width: 650px) {
    .commonsNavMobileMenu {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: normal;
        row-gap: 30px;
        padding: 26px 0 0 0;
    }

    .commonsNavMobileMenu ul {
        display: flex;
        flex-direction: column;
        padding-left: 30px;
        gap: 12px;
        width: 85%;
    }

    .commonsNavMobileMenu ul li {
        max-width: 650px;
        height: 48px;
        display: flex;
        align-items: center;
    }

    .commonsNavMobileMenu ul li.isActive {
        background: #F5FBFF;
    }

    .commonsNavMobileMenu ul li .isActive::after {
        content: '\2713';
        display: block;
        font-size: 18px;
        line-height: 1;
        margin: 10px 10px 10px auto
    }

    .user {
        padding-top: 30px;
    }

    .user:after {
        content: "";
        position: absolute;
        top: 0;
        left: 5%;
        width: 90%;
        height: 1px;
        background-color: #E4E7F2;;
    }

    .userInf {
        width: 100%;
    }
}

@media screen and (max-width: 425px) {
    .commonsNavMobileMenu {
        width: 100%;
        min-width: 0;
    }
}
