.confirmLogout {
    display: flex;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #00000073;
}
.content {
    position: relative;
    background-color: #FFFFFF;
    margin: auto;
    border: 1px solid #F5F6F8;
    width: 430px;
    height: 222px;
    border-radius: 15px;
    padding: 10px 16px;
    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    text-align: center;
}
.title {
    margin-top: 23px;
    font-size: 18px;
    line-height: 1.57;
    color: #3B4168;
}
.contentIcon {
    margin-top: 30px;
}
.action {
    display: flex;
    justify-content: center;
    gap: 15px;
}
.contentBtn {
    border: 1px solid #0071ce;
    border-radius: 4px;
    color: #0071ce;
    min-height: 44px;
    padding: 0 24px;
    transition: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
    background: transparent;
    cursor: pointer;
}
.contentBtn:hover {
    background: #f5fbff;
    border: 1px solid #005eac;
    color: #005eac;
}
@-webkit-keyframes animatetop {
    from {
        top: -300px;
        opacity: 0;
    }
    to {
        top: 0;
        opacity: 1;
    }
}
@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0;
    }
    to {
        top: 0;
        opacity: 1;
    }
}
