.commonsNavProfile {
    user-select: none;
    display: flex;
    align-items: center;
    height: 100%;
}

.enterBtnDark {
    background: black;
    border: 1px solid #fff;
    color: #fff;
}

.commons-nav-profile__items {
    width: 96px;
    display: flex;
    justify-content: flex-end;
}

.commons-nav-profile__items > img {
    height: 24px;
    width: 24px;
    margin-right: 24px;
}

.wrapper {
    padding-left: 28px;
    padding-right: 28px;
    height: 100%;
    display: flex;
    align-items: center;
    color: #0071CE;
}

.user {
    display: flex;
    align-items: center;
    position: relative;
    max-height: 44px;
    height: 100%;
    cursor: pointer;
    padding: 0 28px;
    box-shadow: 0 4px 15px rgba(33, 36, 55, 0.12);
    border-radius: 12px;
}

.userActions {
    overflow: hidden;
    display: none;
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0 4px 15px rgba(33, 36, 55, 0.12);
    border-radius: 12px;
    padding: 18px 28px;
    text-align: left;
    top: 50px;
    width: 100%;
    box-sizing: border-box;
    right: 0;
}

.userActions.show {
    display: block;
    animation: dropdown-open-animation 0.2s ease-in-out;
}

.userActions.hide {
    animation: dropdown-close-animation 0.2s ease-in-out;
}

.userActionsButton {
    color: #6F7C98;
    font-size: 16px;
    line-height: 20px;
    font-family: 'IBM Plex sans', sans-serif;
    border: none;
    padding: 0;
    background: transparent;
    display: block;
    cursor: pointer;
}

.dropdownArrow {
    margin: 0 0 0 8px;
    transition: transform 0.2s ease-in-out;
}

.dropdownArrow.open {
    transform: rotate(180deg);
    filter: invert(36%) sepia(77%) saturate(3864%) hue-rotate(191deg) brightness(86%) contrast(101%);
    margin-bottom: -3px;
}

.userActionsButton:not(:last-child) {
    margin-bottom: 18px;
}

.userActionsButton:hover {
    color: #0071CE;
}

.commons-nav-profile__message {
    margin-right: 20px;
    position: relative;
    display: block;
}
.commons-nav-profile__amount-messages {
    position: absolute;
    right: 0;
    top: 2px;
    width: 17px;
    height: 12px;
    font-size: 8px;
    background-color: #15B012;
    border-radius: 9px;
    text-align: center;
    color: #FFFFFF;
}
.commons-nav-profile__message-icon {
    padding-top: 5px;
}
.userAvatar {
    position: relative;
    height: 20px;
    width: 20px;
    font-size: 0;
}
.userAvatar img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 100%;
}
.default {
    background-color: rgba(0,113,206,0.07);
    padding: 3px;
    margin-top: -3px;
}
.userName {
    margin: 0 0 0 12px;
    white-space: nowrap;
    font-family: 'IBM Plex sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #6F7C98;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 380px;
}
.userNameDark {
    color: #fff;
}
.userName:hover {
    color: #0071CE;
}
.commons-nav-profile__exit-button {
    padding: 0;
    border: 0 !important;
    background-color: inherit;
    height: fit-content !important;
    width: fit-content;
}

@keyframes dropdown-open-animation {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes dropdown-close-animation {
    from {
        opacity: 0;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(-10px);
    }
}
